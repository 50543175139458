<template>
  <v-dialog v-model="value" persistent scrollable max-width="90vw">
    <v-overlay :value="updating">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
        <h4 class="mt-4">Updating products, please wait....</h4>
      </div>
    </v-overlay>
    <v-card>
      <v-toolbar dark color="primary" class="mb-4">
        <v-toolbar-title>Edit {{productType.title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
      <v-row>
        <v-col class="col-6">
          <v-text-field v-model="productType.title" label="Title" dense outlined></v-text-field>
        </v-col>
        <v-col class="col-2">
          <v-text-field :disabled="editing" v-model="productType.productTypeCode" label="Code" dense outlined></v-text-field>
        </v-col>
        <v-col class="col-2">
          <v-text-field v-model="productType.minQty" type="number" label="Minimum Order Qty" dense outlined></v-text-field>
        </v-col>
        <v-col class="col-2">
          <v-text-field v-model="productType.multQty" type="number" label="Order Multiple Qty" dense outlined></v-text-field>
        </v-col>
        <v-col class="col-6">
          <vue-editor
            v-model="productType.longDesc"
          ></vue-editor>
        </v-col>
      </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="mr-4" @click.native="saveProductType()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ProductTypeApi from "@/api/productTypes"
export default {
  props: {
    value: Boolean,
    data: Object
  },
  components: {
    VueEditor
  },
  async mounted() {
  },
  data() {
    return {
      updating: false,
      productType: this.data || {}
    }
  },
  computed: {
    editing() {
      return !!this.data
    }
  },
  async created() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async saveProductType(close) {
      this.updating = true
      ProductTypeApi.saveProductType({
        title: this.productType.title,
        productTypeCode: this.productType.productTypeCode.toUpperCase(),
        minQty: this.productType.minQty,
        multQty: this.productType.multQty,
        longDesc: this.productType.longDesc,
      }).then((results) => {
        this.productType = {...results.data }
        this.updating = false
      }).catch(
        err => {
          console.log('err', err)
          this.updating = false
        }
      )
      this.isDirty = false
      if (close) { this.close() }
    },

  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>

<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h2>Manage Product Types</h2>
      <v-spacer></v-spacer>
      <!-- <v-btn color="success" class="mr-4" @click.native="createProductType()">
        New Product Type
      </v-btn> -->
    </div>
    <v-data-table @click:row="editProductType" :items="productTypes" :headers="headers" :disable-pagination="true" :hide-default-footer=true dense>
      <template v-slot:[`item.longDesc`]="{ item }">
        <div v-html="item.longDesc"></div>
      </template>
    </v-data-table>
    <product-type-dialog v-if="openDialog" v-model='openDialog' :data="currentProductType" @close="closeDialog" />
  </v-container>
</template>

<script>
import ProductTypeDialog from './components/ProductTypeDialog'
import ProductTypeApi from '@/api/productTypes'
import { mapState } from 'vuex'

export default {
  components: {
    ProductTypeDialog
  },
  data() {
    return {
      openDialog: false,
      headers: [
        { text: 'Product', value: 'title' },
        { text: 'Code', value: 'productTypeCode', width: 80 },
        { text: 'Description', value: 'longDesc' },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      mode: 'preview'
    }
  },
  created() {
  },
  mounted: function () {
  },
  computed: mapState({
    productTypes: (state) => state.products.productTypes,
  }),
  watch: {},
  methods: {
    createProductType() {
      this.currentProductType = null
      this.openDialog = true
    },
    editProductType(evt) {
      this.currentProductType = evt
      this.openDialog = true
    },
    closeDialog(element) {
      this.openDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
